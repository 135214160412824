import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Box from '@mui/material/Box'
import { useTheme } from '@mui/material/styles';
import { Typography, useMediaQuery } from '@mui/material';
import { Grid } from '@mui/material';
import Fechadesde from '@mui/material/TextField';
import Fechahasta from '@mui/material/TextField';
import Sucursal from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { DataGrid } from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import Prestamos from './prestamos';
import Iconpdf from 'assets/pdf/iconpdf';
import Dialog from '@mui/material/Dialog';
import { Page, Text, View, Document, StyleSheet, PDFViewer } from '@react-pdf/renderer';
import { useMaterialUIController } from "context";
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import profileimage from '../assets/images/profileimage.webp'

function DetalleQrexpediente(props) {
  const {nombrenegocio,id,data} = useParams();
  const [rows, setRows] = useState([])
  const [balanceatraso, setBalanceatraso] = useState([])
  const [cuotavencida, setCuotavencida] = useState([])


  const[result, setResult] = useState()
  const[comp, setComp] = useState()

  useEffect(() => {

    const request = {
      method: 'POST',
      headers: {'Content-Type':'application/json'},
      body: JSON.stringify({'chasis':data,'id':id}) 
    }
    fetch(process.env.REACT_APP_SERVIDOR+'/api/qrrecuperarexpediente',request)
    .then(function(response){
      if (response.status === 200) {
          response.json().then(function(data){

            setComp(data["micomp"][0])
            setResult(data["data"][0])
            
          })
          
      }
      if (response.status === 400) {
          response.json().then(function(data){
            setRows([]) 
            alert(data["Error"])
            
          })
      }})
  },[]);
  
    return(
        <Box marginLeft={5} marginRight={5}>
            {result!=undefined?
            <Box>
            <Box>
                <Box display={"flex"} flexDirection={"row"} flexWrap={"wrap"} container marginTop={8} spacing={0} style={{marginBottom:5,borderRadius:10,backgroundColor:"white"}}>
                    <Box>
                        <img src={profileimage} style={{backgroundColor:"grey",width:"130px",height:"130px",marginTop:"15px",marginLeft:"25px",borderRadius:"100px"}}></img>
                    </Box>
                    
                    <Box marginLeft={5} marginTop={2}>
                        <Typography variant="h5" marginTop={"20px"} fontWeight={"bold"} style={{alignSelf:"center"}}>{result["nombres"]}</Typography>
                        <Typography variant="h5" fontWeight={"bold"} style={{alignSelf:"center"}} >{result["cedula"]}</Typography>
                    </Box>

                </Box>
            </Box>

            <Box>
                <Box container marginTop={5} spacing={0} style={{marginBottom:5,borderRadius:10,backgroundColor:"white"}}>
                    <Box>
                        <Grid container padding={3}>
                            <Grid item sx={6}>
                                <p><strong style={{color:"#5e5757"}}>Compañia:</strong> {comp["ncomp"]}</p>
                                <p><strong style={{color:"#5e5757"}}>Telefono:</strong> {comp["telefono"]}</p>
                                <p><strong style={{color:"#5e5757"}}>Chasis:</strong> {result["chasis"]}</p>
                                <p><strong style={{color:"#5e5757"}}>Color:</strong> {result["colore"]}</p>
                                <p><strong style={{color:"#5e5757"}}>Marca:</strong> {result["marca"]}</p>
                                <p><strong style={{color:"#5e5757"}}>Modelo:</strong> {result["modelo"]}</p>
                            </Grid>

                        </Grid>
                    </Box>


                </Box>
            </Box>
            </Box>
            :''}

        </Box>

    );    
}
export default DetalleQrexpediente


