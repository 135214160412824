import React, { useState, useContext, useEffect } from 'react';
import Box from '@mui/material/Box'
import { useTheme } from '@mui/material/styles';
import { Typography, useMediaQuery } from '@mui/material';
import { Grid } from '@mui/material';
import Fechadesde from '@mui/material/TextField';
import Fechahasta from '@mui/material/TextField';
import Sucursal from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { DataGrid } from '@mui/x-data-grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import MDButton from 'components/MDButton';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';
import Administracion from './administracion';
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Dialogtransferir from './dialogTransferir';
import Transitotranf from './transitotranf';
import Listtransf from './listtransf';
import { BorderColorRounded, ContentPasteGoRounded, GetAppOutlined, GetAppRounded } from '@mui/icons-material';


var drawerWidth = 350;

function Transferenciasmatriculas(props) {
  const [programa,setPrograma] = useState('');
  const [desplegarmenu,setDesplegarmenu] = useState(false);
  const [programacargado,setProgramacargado] = useState(false)
  
  const[fecha1,setFecha1] = useState("")
  const[fecha2,setFecha2] = useState("")
  const[nosucursal,setNosucursal] = useState("")
  const[rows,setRows] = useState([])
  const[rowsOriginal,setRowsOriginal] = useState([])
  const[solochasis,setsolochasis] = useState([])
  const[sucursales,setSucursales] = useState([])
  const[sucursalesTrans,setSucursalesTrans] = useState([])

  const[currentsucursal,setCurrentSucursal] = useState([])
  const[transferidos,setTransferidos] = useState([])
  const[estatuspago,setEstatuspago] = useState("No Cobrado")
  const[open,setOpen] = useState(false)
  const[noprest,setNoprest] = useState(0)
  const[chasis,setChasis] = useState("")
  const[position, setPosition] = useState(0)

  

  const handledesplegarmenu = () => {
    setDesplegarmenu(!desplegarmenu);
  };
  
  const drawerWidth = 240



  useEffect(() => {
    const request = {
      method: 'POST',
      headers: {'Content-Type':'application/json'},
      body: JSON.stringify({'password':localStorage.getItem("password"),'usuario':localStorage.getItem('usuario')}) 
    }
    fetch(process.env.REACT_APP_SERVIDOR+'/api/recuperasucursales',request)
    .then(function(response){
      if (response.status === 200) {
          response.json().then(function(data){
             //rows = [data["facturacion"]]
             setSucursales(data["sucursales"])  
             console.log(data["sucursales"]) 
          })
          
      }
      if (response.status === 400) {
          response.json().then(function(data){
            ///setRows([]) 
            alert(data["Error"])
            
          })
      }})
  },[]);


  const pantalla = useTheme()  
  const pantxssm = useMediaQuery(pantalla.breakpoints.between('xs','sm'))
  const pantsmmd = useMediaQuery(pantalla.breakpoints.between('sm','md'))
  const pantmdlg = useMediaQuery(pantalla.breakpoints.between('md','lg'))
  const pantlgxl = useMediaQuery(pantalla.breakpoints.between('lg','xl'))
  const pantxlxxl = useMediaQuery(pantalla.breakpoints.up('xl'))

  
  
  const columns = [
  {
    field: 'Marca',
    headerName: 'Marca',
    type: 'string',
    width: 100,
  },
  {
    field: 'Modelo',
    headerName: 'Modelo',
    type: 'string',
    width: 100,
  },
  {
    field: 'Color',
    headerName: 'Color',
    type: 'string',
    width: 100,
  },
  {
    field: 'Chasis',
    headerName: 'Chasis',
    type: 'string',
    width: 210,
  },
  {
    field: 'Valor',
    headerName: 'Valor',
    type: 'string',
    width: 100,
  },
  
  {
    field: ' ',
    width: 150,
    renderCell:(params)=>{
      return(
        <MDButton variant="gradient" color="info" onClick={()=>agregartransferidos(params.row)}  fullWidth>seleccionar</MDButton>
      );
    }
  },
  
];


function agregartransferidos(row){
  const newItems = rows.filter(item => item !== row);
  setRows(newItems);
  setRowsOriginal(rowsOriginal.filter(item => item !== row))
  
  setTransferidos([...transferidos,row])

}

function eliminarTransferido(row){
  const newItems = transferidos.filter(item => item !== row);
  setTransferidos(newItems);

  setRows([...rows,row])
  setRowsOriginal([...rows,row])

}

function buscarmotores(){

  setTransferidos([])

  const request = {
    method: 'POST',
    headers: {'Content-Type':'application/json'},
    body: JSON.stringify({'sucursal': currentsucursal.split("-")[0],'password':localStorage.getItem("password"),usuario:localStorage.getItem('usuario')}) 
  }
  fetch(process.env.REACT_APP_SERVIDOR+'/api/informemotorestransf',request)
  .then(function(response){
    if (response.status === 200) {
        response.json().then(function(data){
          //rows = [data["facturacion"]]
           setRows(data["motor"])
           setsolochasis(data["solochasis"])
           setRowsOriginal(data["motor"])

           
        })
        
    }
    if (response.status === 400) {
        response.json().then(function(data){
          setRows([]) 
          alert(data["Error"])
          
        })
    }
  })
}

function handlebuscarchasis() {
 
    
    const array2 = transferidos.filter(value => value.Chasis == chasis);
    if(array2.length==0){
      const array = rowsOriginal.filter(value => value.Chasis == chasis);
      if(array.length != 0){
        setRows(array)
      } 
      else{
        setRows(rowsOriginal)
      }
   

    }
    else{
      setRows(rowsOriginal)
    }



    return(<div></div>)

 }


function closeTransfer(){
  setTransferidos([])
}

const handleClose = () => {
  setOpen(false);
};

function alertar(cellValues){
  alert(cellValues)
}

    return(
     <DashboardLayout>
     <Administracion title="Transferencia de Motores"/> 
     
     <Box component="main" sx={{ p: 3 }} >
        <Grid container direction="row" justifyContent={"center"} >
            <Grid item xs = {12} sm={3} lg={4} style={{padding:20,backgroundColor:position==0?"#34b1eb":'white',justifyContent:"center",alignItems:"center",display:"flex",flexDirection:"column"}} onClick={()=>setPosition(0)} >
              <BorderColorRounded style={{color:position==0?'white':'#515557',height:34,width:34}} ></BorderColorRounded>
              <p style={{color:position==0?'white':'#515557',fontWeight:"bold"}}>Creacion</p>
            </Grid>
            <Grid item xs = {12} sm={3} lg={4} style={{padding:20,backgroundColor:position==1?"#34b1eb":'white',justifyContent:"center",alignItems:"center",display:"flex",flexDirection:"column"}} onClick={()=>setPosition(1)} >
              <GetAppRounded style={{color:position==1?'white':'#515557',height:34,width:34}} ></GetAppRounded>
              <p style={{color:position==1?'white':'#515557',fontWeight:"bold"}}>Recepcion</p>
            </Grid>
            <Grid item xs = {12} sm={3} lg={4} style={{padding:20,backgroundColor:position==2?"#34b1eb":'white',justifyContent:"center",alignItems:"center",display:"flex",flexDirection:"column"}} onClick={()=>setPosition(2)} >
              <ContentPasteGoRounded style={{color:position==2?'white':'#515557',height:34,width:34}} ></ContentPasteGoRounded>
              <p style={{color:position==2?'white':'#515557',fontWeight:"bold"}}>Seguimiento</p>
            </Grid>

        </Grid>

        {position==0?
        <div>
          {transferidos.length!=0?
          <Dialogtransferir transferidos={transferidos} sucursales={sucursales} current={currentsucursal} eliminarTransferido={eliminarTransferido} closeTransfer={closeTransfer}></Dialogtransferir>
          :''}
        <Grid container style={{marginTop:"15px"}}>
          <Grid item xs={12} sm={12} md={12} lg={4} marginTop={3}>
            <h3>seleccionar chasis...</h3>
            <Autocomplete
                style={{marginTop:"0px",backgroundColor:"white",width:"300px"}}
                    variant = "standard"    
                    disablePortal
                    id="combo-box-demo"
                    options={sucursales}
                    value = {currentsucursal}
                    onBlur={(event) => setCurrentSucursal(event.target.value)}
                    sx = {{ disableUnderline: true}}
                                                          
                    renderInput={(params) => 
                    <TextField {...params} 
                      variant = "standard"
                      label="seleccionar sucursal" 
                      sx={{boxShadow: 4, borderRadius: 2,width:"300px" ,height:"55px",backgroundColor: "white"}}
                      InputLabelProps={{
                        shrink: "true",
                        style:{
                          paddingLeft: "10px",
                          paddingTop: "5px",
                          disableUnderline: true,
                        }
                      }}
                      InputProps={{
                        ...params.InputProps,disableUnderline:true,borderRadius:5,
                        style:{
                          fontSize: "14px",
                          marginLeft: "5px",
                          marginTop: "20px",  
                        }}}
                    />}
            />
          </Grid>
          <Grid item lg={2} marginTop={9} marginLeft={-6}>
              <MDButton variant="gradient" color="success" onClick={()=>buscarmotores()}  fullWidth>Buscar</MDButton>
            </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12} marginTop={4}  style={{backgroundColor:"white"}}>
            <Autocomplete
                style={{marginTop:"0px",backgroundColor:"white",width:"400px"}}
                    variant = "standard"    
                    disablePortal
                    id="combo-box-demo"
                    options={solochasis}
                    value={chasis}
                    onBlur={(event) => setChasis(event.target.value)}
                    sx = {{ disableUnderline: true}}
                                                          
                    renderInput={(params) => 
                    <TextField {...params} 
                      variant = "standard"
                      label="Buscar chasis" 
                      sx={{boxShadow: 4, borderRadius: 2,width:"400px" ,height:"55px",backgroundColor: "white"}}
                      InputLabelProps={{
                        shrink: "true",
                        style:{
                          paddingLeft: "10px",
                          paddingTop: "5px",
                          disableUnderline: true,
                        }
                      }}
                      InputProps={{
                        ...params.InputProps,disableUnderline:true,borderRadius:5,
                        style:{
                          fontSize: "14px",
                          marginLeft: "5px",
                          marginTop: "20px",  
                        }}}
                    />}
               />
            <MDButton variant="gradient" color="success" onClick={()=>handlebuscarchasis()}  >Buscar</MDButton>
            <br></br>
            <br></br>
            <DataGrid
              style = {{height:400,backgroundColor:"white",borderRadius:"10px"}}
              rows={rows}
              columns={columns}
              pageSize={5}
              rowsPerPageOptions={[5]}
              experimentalFeatures={{ newEditingApi: true }}
              rowHeight={38}
            />
          </Grid>
        </Grid>      
        </div>
        :''}     
        {position==1?
          <Transitotranf></Transitotranf>
        :''}     
        {position==2?
        <Listtransf sucursales={sucursales}></Listtransf>
        :''}
     </Box>

     {/*ventana de dialogo*/}
     <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style = {{width:"100%", height:"500px"}}
 
      >
        <DialogTitle id="alert-dialog-title">
          {"Eliminacion Registro"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
                Desea Eliminar este registro de Incauto?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button style = {{color: "white"}} onClick={handleClose} autoFocus>
            NO
          </Button>
        </DialogActions>
      </Dialog>

      </DashboardLayout> 

    );    
}
export default Transferenciasmatriculas
