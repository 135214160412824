/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Material Dashboard 2 React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Material Dashboard 2 React layouts
import Dashboard from "layouts/dashboard";
import Entrar from "layouts/login";
import InformeMotores from "Motores/informemotores";
import InformeMatriculasCobradas from "Motores/informematriculascobradas";
import Cobrosincautosfechas from "Motores/cobrosincautosfechas";
import Eliminarregistroincauto from "Motores/eliminarregistroincauto";
import Eliminarcobromatricula from "Motores/eliminarcobromatriculas";
import Traspaso from "Motores/informetraspaso"
import InformeMotoresFacturados from "Facturacion/informemotoresfacturados";
import ListadoChasisIncautados from "Facturacion/listadochasisincautados";
import CambiarChasisFactura from "Facturacion/cambiarchasisfacturas";
import EliminarMotoresFacturados from "Facturacion/eliminarmotoresfacturados";
import Informepagos from "Pagos/informepagos";
import Auditoriarecibos from "Pagos/auditoriarecibos";
import Informepagoscancelados from "Pagos/informepagoscancelados";
import Interescobradoperiodo from "Pagos/interescobradoperiodo";
import Proyectadocobros from "Pagos/proyectadocobros";
import InformePrestamo from "Prestamos/informeprestamos";
import ListarCanceladoFecha from "Prestamos/listarcanceladosporfechas";
import RestaurarCancelarPrestamo from "Prestamos/restaurarcancelarprestamos";
import Inventariomatriculas from "./Motores/inventariomatriculas";
import ConsultaMatriculas from "Motores/consultamatricula";

// @mui icons
import Icon from "@mui/material/Icon";
import Transferenciasmatriculas from "Motores/transferenciamatriculas";
import DashboardPantalla from "layouts/dashboard/pantallacompleta";

const routes = [  
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    component: <Dashboard />,
  },
  {
    type: "collapse",
    name: "Motores",
    key: "motores",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/motores/informemotores",
    component: <InformeMotores />,
  },
  {
    key: "ConsultaMatriculas",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/motores/consultamatriculas",
    component: <ConsultaMatriculas />,
  },

  {
    key: "matriculastransferencia",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/motores/transferenciaschasis",
    component: <Transferenciasmatriculas />,
  },
 
  {
    key: "informematriculascobradas",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/motores/informematriculascobradas",
    component: <InformeMatriculasCobradas />,
  },
  {
    key: "eliminarregistroincauto",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/motores/eliminarregistroincauto",
    component: <Eliminarregistroincauto />,
  },
  
  {
    key: "cobrosincautosfechas",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/motores/cobrosincautosfechas",
    component: <Cobrosincautosfechas />,
  },
  
  {
    key: "eliminarcobromatricula",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/motores/eliminarcobromatricula",
    component: <Eliminarcobromatricula />,
  },
  
  {
    key: "traspaso",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/motores/traspaso",
    component: <Traspaso />,
  },
  
  {
    type: "collapse",
    name: "Facturacion",
    key: "facturacion",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/facturacion/informemotoresfacturados",
    component: <InformeMotoresFacturados />,
  },
  
  {
    key: "listadochasisincautados",
    route: "/facturacion/listadochasisincautados",
    component: <ListadoChasisIncautados />,
  },
  
  {
    key: "cambiarchasisfacturas",
    route: "/facturacion/cambiarchasisfacturas",
    component: <CambiarChasisFactura />,
  },
  
  {
    key: "eliminarmotoresfacturados",
    route: "/facturacion/eliminarmotoresfacturados",
    component: <EliminarMotoresFacturados />,
  },
  
  
  {
    type: "collapse",
    name: "Pagos",
    key: "pagos",
    icon: <Icon fontSize="small">receipt_long</Icon>,
    route: "/pagos/auditoriarecibos",
    component: <Auditoriarecibos />,
  },
 
  {
    key: "auditoriarecibos",
    route: "/pagos/auditoriarecibos",
    component: <Auditoriarecibos />,
  },
  {
    key: "informepagos",
    route: "/pagos/informepagoscancelados",
    component: <Informepagoscancelados />,
  },
  {
    key: "intresescobradosperiodos",
    route: "/pagos/interescobradosperiodos",
    component: <Interescobradoperiodo />,
  },
  {
    key: "proyectadocobros",
    route: "/pagos/proyectadocobros",
    component: <Proyectadocobros />,
  },
 
  {
    type: "collapse",
    name: "Prestamos",
    key: "prestamos",
    icon: <Icon fontSize="small">receipt_long</Icon>,
    route: "/prestamos/informeprestamos",
    component: <InformePrestamo />,
  },
 
  {
    key: "listarcanceladosporfechas",
    route: "/prestamos/listarcanceladosporfechas",
    component: <ListarCanceladoFecha />,
  },
 
  {
    key: "restaurarcancelarprestamos",
    route: "/prestamos/restaurarcancelarprestamos",
    component: <RestaurarCancelarPrestamo />,
  },
 
  {
    key: "inventariomatriculas",
    route: "/motores/inventariomatriculas",
    component: <Inventariomatriculas />,
  },
  {
    key: "inventariomatriculas",
    route: "/motores/dashaboard",
    component: <DashboardPantalla />,
  },
 
  {
    type: "collapse",
    name: "Sign In",
    key: "sign-in",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/login",
    component: <Entrar />,
  },
  {
    name: "Sign Up",
    key: "sign-up",
    route: "/login",
    component: <Entrar />,
  },


];

export default routes;
