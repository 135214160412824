import React, { useState,useEffect } from 'react';
import { useTheme } from '@emotion/react';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Grid } from '@mui/material';
import Fechadesde from '@mui/material/TextField';
import Fechahasta from '@mui/material/TextField';
import Sucursal from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { DataGrid } from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Administracion from './administracion';
import theme from "assets/theme";
import Iconpdf from 'assets/pdf/iconpdf';
import { Page, Text, View, Document, StyleSheet, PDFViewer } from '@react-pdf/renderer';
import { useMaterialUIController } from "context";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

export default function Inventariomatriculas() {
  var tcosto = 0.00
  var tvalor = 0.00

  const[fecha1,setFecha1] = useState("")
  const[fecha2,setFecha2] = useState("")
  const[nosucursal,setNosucursal] = useState("")
  const[rows,setRows] = useState([])
  const[opendialog,setOpendialog] = useState(false)
  
  const opcionstatus = ['T=Todos','I=Incautados','V=Vendido', 'N=Normal']
  const[status,setStatus] = useState(opcionstatus[0])
  const[sucursalnueva,setSucursalnueva] = useState("")
  const[sucursalactual1,setSucursalactual1] = useState("")
  const[chasis,setChasis] = useState("")
  const[listasucursales,setListasucursales] = useState([])
  const { breakpoints,typography } = theme;
  const drawerWidth = 240
  const [abrirpdf,setAbrirpdf] = useState(false)
  
  const pantxssm = useMediaQuery(breakpoints.up('xs','sm'))
  const pantsmmd = useMediaQuery(breakpoints.between('sm','md'))
  const pantmdlg = useMediaQuery(breakpoints.between('md','lg'))
  const pantlgxl = useMediaQuery(breakpoints.between('lg','xl'))
  const pantxlxxl = useMediaQuery(breakpoints.up('xl'))
  const [presentarpdf,setPresentarpdf] = useState(false)
  
  const theme1 = useTheme()
  const matches = useMediaQuery(theme1.breakpoints.down('sm'))

  const[controller] = useMaterialUIController()
  const {nombreNegocio} = controller 


  var stilos
  if (pantxssm) {
    stilos = {
      width: "300px",
      height: "10px"
    }
  }
  
  var rows1 = []    
  const columns = [
  { field: 'id', headerName: 'DOC #', width: 75 },
  {
    field: 'fecha',
    headerName: 'Fecha',
    width: 125,
    editable: true,
  },
  {
    field: 'marca',
    headerName: 'Marca',
    width: 150,
    editable: true,
  }, {
    field: 'modelo',
    headerName: 'Modelos',
    type: 'string',
    width: 110,
    editable: true,
  },
  {
    field: 'colore',
    headerName: 'Color',
    description: 'This column has a value getter and is not sortable.',
    sortable: false,
    width: 100,
  },
  {
    field: 'chasis',
    headerName: 'Chasis',
    width: 200,
  },
  {
    field: 'costo',
    headerName: 'Costo',
    type: 'string',
    width: 100,
  },

  {
    field: 'precio',
    headerName: 'Precio',
    type: 'string',
    width: 100,
  },

  {
    field: 'estado',
    headerName: 'Status',
    type: 'string',
    width: 75,
  },
  {
    field: 'condicion',
    headerName: 'Condicion',
    type: 'string',
    width: 100,
  },

  {
    field: ' ',
    renderCell:(params)=>{
      return(
        <Button variant="contained" color="primary" style={{fontSize:"10px",color:"white"}} onClick={()=>clicktransferir(params.row.sucursal,params.row.chasis)} 
      >Transferir</Button>
      );
    }
  },
  
];

useEffect(() => {
  const request = {
    method: 'POST',
    headers: {'Content-Type':'application/json'},
    body: JSON.stringify({'fechadesde': fecha1, 'fechahasta': fecha2, 'sucursal': nosucursal,'password':localStorage.getItem("password"),'usuario':localStorage.getItem('usuario')}) 
  }
  fetch(process.env.REACT_APP_SERVIDOR+'/api/recuperasucursales',request)
  .then(function(response){
    if (response.status === 200) {
        response.json().then(function(data){
           //rows = [data["facturacion"]]
           setListasucursales(data["sucursales"])  
          
        })
        
    }
    if (response.status === 400) {
        response.json().then(function(data){
          setRows([]) 
          alert(data["Error"])
          
        })
    }})
},[]);



function clicktransferir(sucursalact,chasis){
   setChasis(chasis)
   setOpendialog(true)
}

function procesarrequerimiento(event){
  let aerror = false
  const request = {
      method: 'POST',
      headers: {'Content-Type':'application/json'},
      body: JSON.stringify({'fechadesde': fecha1, 'fechahasta': fecha2, 'sucursal': nosucursal,'status': status,'password':localStorage.getItem("password"),'usuario':localStorage.getItem('usuario')}) 
  }
  fetch(process.env.REACT_APP_SERVIDOR+'/api/informemotores',request)
  .then(function(response){
      if (response.status === 200) {
          response.json().then(function(data){
            //rows = [data["facturacion"]]
             setRows(data["motor"])
             setListasucursales(data["sucursales"])  
            
          })
          
      }
      if (response.status === 400) {
          response.json().then(function(data){
            setRows([]) 
            alert(data["Error"])
            
          })
      }
  })
 
}

function modificarsucursal() {
  //backend para modificar sucursal.
  let aerror = false
  const request = {
    method: 'POST',
    headers: {'Content-Type':'application/json'},
    body: JSON.stringify({'sucursalacambiar': sucursalnueva,'sucursalactual': sucursalactual1, 'chasis': chasis,'password':localStorage.getItem("password"),'usuario':localStorage.getItem('usuario')}) 
  }
  fetch(estado.servidor+'/api/modificasucursal',request)
  .then(function(response){
    if (response.status === 200) {
        response.json().then(function(data){
           //rows = [data["facturacion"]]
           //rows1 = [{id: '2', fecha: '2023-01-01',nombres: 'manuel',apellidos: 'asdasdsa',chasis: 'aaaa',financia:'2500.00' }]
           setOpendialog(false)
           alert(data["ok"])             
        })
        
    }
    if (response.status === 400) {
        response.json().then(function(data){
          alert(data["Error"])
          
        })
    }
  })
}

function alertar(cellValues){
  alert(cellValues)
}

const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: '#E4E4E4'
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1
  }
});

const MyDocument = () => (  
  <PDFViewer style={{height: "550px"}}>
  <Document>
    <Page size="A4">
      <View style={{textAlign: "center", width: "94%", fontSize: 14,textTransform: "uppercase", marginTop:"15px" }} fixed> 
          <Text >{nombreNegocio}</Text>
      </View>
      
      <View style={{textAlign: "center", width: "94%", fontSize:9,textTransform: "uppercase" }} fixed> 
          <Text >Reporte de Motores</Text>
      </View>
      <View style={{textAlign: "center", width: "94%", fontSize:9,textTransform: "uppercase" }} fixed> 
          <Text>  </Text>
      </View>
          
      <View style={{display: "flex", alignItems:"center",flexDirection: "row",justifyContent:"left", width: "94%",height:"25px",fontSize: 10, backgroundColor: "gray", color: "black", marginLeft: "15px"}} fixed> 
          <Text style={{width: "100px", justifyContent: "left"}} fixed>Prest#</Text>
          <Text style={{width: "100px", justifyContent: "left"}} fixed>Fecha</Text>
          <Text style={{width: "100px", justifyContent: "left"}} fixed>Marca</Text>
          <Text style={{width: "100px", justifyContent: "left"}} fixed>Modelo</Text>
          <Text style={{width: "100px", justifyContent: "left"}} fixed>Colore</Text>
          <Text style={{width: "100px", justifyContent: "left"}} fixed>Chasis</Text>
          <Text style={{width: "100px", justifyContent: "left"}} fixed>Costo</Text>
          <Text style={{width: "100px", justifyContent: "left"}} fixed>Precio</Text>
          <Text style={{width: "100px", justifyContent: "left"}} fixed>Status</Text>
          <Text style={{width: "100px", justifyContent: "left"}} fixed>Condicion</Text>
        
      </View>
      {rows.map((filas,i)  =>  
        <View style={{display: "flex", alignItems:"center",flexDirection: "row",justifyContent:"left", width: "94%",height:"15px",fontSize: 10, color: "black",marginLeft:"15px"}} > 
          <Text style={{width: "100px", justifyContent: "left"}} fixed>{filas.id}</Text> 
          <Text style={{width: "100px", justifyContent: "left"}} fixed>{filas.fecha}</Text>
          <Text style={{width: "100px", justifyContent: "left"}} fixed>{filas.marca}</Text>
          <Text style={{width: "100px", justifyContent: "left"}} fixed>{filas.modelo}</Text>
          <Text style={{width: "100px", justifyContent: "left"}} fixed>{filas.colore}</Text>
          <Text style={{width: "100px", justifyContent: "left"}} fixed>{filas.chasis}</Text>
          <Text style={{width: "100px", justifyContent: "left"}} fixed>{filas.costo}</Text>
          <Text style={{width: "100px", justifyContent: "left"}} fixed>{filas.precio}</Text>
          <Text style={{width: "100px", justifyContent: "left"}} fixed>{filas.estado}</Text>
          <Text style={{width: "100px", justifyContent: "left"}} fixed>{filas.condicion}</Text>
                       
        </View>
      )}
      {rows.forEach(function(totales){
        tcosto = tcosto + parseFloat(totales.costo)
        tvalor = tvalor + parseFloat(totales.precio)       
      })}
      <Text> </Text>
      <View style={{display: "flex", alignItems:"center",flexDirection: "row",justifyContent:"left", width: "94%",height:"25px",fontSize: 10, backgroundColor: "white", color: "black", marginLeft: "15px"}}> 
          <Text style={{width: "100px", justifyContent: "left"}} fixed>Totales</Text>
          <Text style={{width: "100px", justifyContent: "left"}} fixed> </Text>
          <Text style={{width: "100px", justifyContent: "left"}} fixed> </Text>
          <Text style={{width: "100px", justifyContent: "left"}} fixed> </Text>
          <Text style={{width: "100px", justifyContent: "left"}} fixed> </Text>
          <Text style={{width: "100px", justifyContent: "left"}} fixed> </Text>
          <Text style={{width: "100px", justifyContent: "left"}} fixed>{tcosto.toFixed(2)}</Text>
          <Text style={{width: "100px", justifyContent: "left"}} fixed>{tvalor.toFixed(2)} </Text>
          <Text style={{width: "100px", justifyContent: "left"}} fixed> </Text>
          <Text style={{width: "100px", justifyContent: "left"}} fixed> </Text>
        
      </View>
      
    </Page>
  </Document>
  </PDFViewer>
);


function handlepdf() {
  var MyDocument
  if (rows.length == 0) {
     alert("No hay datos para procesar este reporte")
  } else {
    setPresentarpdf(true)       
  } 

}

    return(
     <DashboardLayout>
     <Administracion title="Inventario Matriculas"/>  
     <Box component="main" sx={{marginLeft:"15px",marginTop:"10px" }} > 
        
        <Grid container direction="row" justifyContent="flex-start" spacing={2}>
            <Grid item xs = {12} sm={6} md={6} lg={3} >
                <Fechadesde type="date" id="outlined-helperText" label="Fecha Desde"
                onChange={(event)=>setFecha1(event.target.value)}
                variant='standard'
                InputLabelProps={{shrink: true, style:{padding:"10px"}}}
                InputProps={{
                  disableUnderline: true, // <== added this  
                  style: {
                    height: "40px",
                    width: "100%",
                    padding:"10px"

                  }
                }}
                style={{borderRadius:"10px",backgroundColor:"white"}}
                sx={{ boxShadow: 5,}} 
                 fullWidth
                ></Fechadesde>                  
            </Grid>
   
            <Grid item xs = {12} sm = {6} md={6} lg={3} >
                <Fechahasta type="date" id="outlined-helperText" label="Fecha Hasta"
                onChange={(event)=>setFecha2(event.target.value)}
                variant='standard'
                InputLabelProps={{shrink: true, style:{padding:"10px"}}}
                InputProps={{
                  disableUnderline: true, // <== added this  
                  style: {
                    height: "40px",
                    width: "100%",
                    padding:"10px"

                  }
                }}
                style={{borderRadius:"10px",backgroundColor:"white"}}
                sx={{ boxShadow: 5,}} 
                fullWidth
                ></Fechahasta>                  
            </Grid>
            <Grid item xs = {12} sm = {6} md={6} lg={3} >
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={listasucursales}
                  style = {{width:"100%", height: "55px", borderRadius:"10px", backgroundColor:"white", disableUnderline:"true"}}
                  renderInput={(params) => <Sucursal variant="standard" label="Sucursal"  {...params} inputProps={{...params.inputProps, disableUnderline:true, style:{height:"32px",padding:"5px"}}} InputLabelProps={{shrink: true,disableUnderline:true,style:{marginLeft:"10px",marginTop:"5px"}}}/>}
                  
                  onChange={(event,value)=>setNosucursal(value)}
                />                 
            </Grid>

            <Grid item xs = {12} sm = {6} md={6} lg={3} >
                <Autocomplete id="controllable-states-demo"
                defaultValue= {status}
                options = {opcionstatus}
                style = {{width: "100%",height:"55px",backgroundColor:"white",borderRadius:"10px"}}
                renderInput={(params) => <Sucursal variant='standard' label="status" {...params} InputProps={{...params.inputProps,disableUnderline:true ,style:{height:"0px",backgroundColor:"white",padding:"10px",marginTop:"24px"}}} InputLabelProps={{shrink: true, style:{marginLeft:"10px",marginTop:"5px"}}}/>}
                sx={{ boxShadow: 5,}}                
                onChange={(event,value)=>setStatus(value)}
                fullWidth
                />                 
            </Grid>
            
            <Grid item xs = {12} sm = {12} md={12} >
                <Box display="flex">
                <Button variant='contained' sx={{width:"150px",height:"45px",boxShadow:5}} style={{color: "white",backgroundColor:"green"}}  onClick={()=>procesarrequerimiento()}>Procesar</Button>                 
                 <Box style={{marginLeft:"30px",backgroundColor:"red",width:"150px",borderRadius:"10px",boxShadow:5,height:"41px"}}>
                   <Iconpdf style={{width:"150px",height:"41px"}} width="150px" onClick={() => handlepdf()}></Iconpdf>   
                     {presentarpdf === true && (
                      <Dialog open={presentarpdf}
                       maxWidth = "lg"
                       fullWidth={true}
                       onClose={()=>setPresentarpdf(false)}
                       style = {{width:"100%", height:"500px"}}> 
                       <MyDocument/>     
                    </Dialog>)}
        
             </Box>
        </Box>

            </Grid>

        
            <Grid item xs = {12} sm = {12} md={12} >
            </Grid>
         
        
        
        </Grid>

        <Grid container style={{marginTop:"15px"}}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <DataGrid
              style = {{height:400,backgroundColor:"white",borderRadius:"10px"}}
              rows={rows}
              columns={columns}
              pageSize={5}
              rowsPerPageOptions={[5]}
              checkboxSelection
              disableSelectionOnClick
              experimentalFeatures={{ newEditingApi: true }}
              rowHeight={38}
            />
          </Grid>
        </Grid>
        
     </Box>
     
     {/* Dialog Box*/}
     <Dialog
        open={opendialog}
        style = {{width:"100%", height:"500px"}}
      >
        <DialogTitle id="alert-dialog-title">
          {"Digite por la sucursal a transferir?"}
        </DialogTitle>
        <DialogContent>
             <Sucursal
                onChange={(event)=>setSucursalnueva(event.target.value)}
                inputProps={{
                style: {
                  height: "5px",
                  width: "300px"
                    }
                }}
              />   
          <DialogActions>
                <Button variant="contained" style={{color: "white"}} onClick={()=>modificarsucursal()}>Aceptar</Button>
                <Button variant="contained" style={{color: "white"}} onClick={()=>setOpendialog(false)}>Cancelar</Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
      
     </DashboardLayout>

    );    
}

